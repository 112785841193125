import { useCallback, useContext } from 'react';
import ModalContext from './context';

export const useModalContext = () => useContext(ModalContext);

export const useCloseModal = (onCloseModal?: () => void) => {
  const modalContext = useModalContext();
  const { closeModal } = modalContext ?? {};

  const handleCloseModal = useCallback(() => {
    onCloseModal?.();
    closeModal?.();
  }, [closeModal, onCloseModal]);

  if (!closeModal) {
    return null;
  }

  return handleCloseModal;
};

export const useSetModalTitleBackdrop = () => {
  const modalContext = useModalContext();
  const { setModalTitleBackdrop } = modalContext ?? {};

  return setModalTitleBackdrop;
};

export const useSetModalTitle = () => {
  const modalContext = useModalContext();
  const { setModalTitle } = modalContext ?? {};

  return setModalTitle;
};
