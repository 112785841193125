import { useState, useEffect, TextareaHTMLAttributes, ChangeEvent, Ref } from 'react';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { mediaSmMax } from 'helpers/breakpoints';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  hasError?: boolean;
  id?: string;
  name?: string;
  hasValue?: boolean;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onBlur?: (e) => void;
  maxLength?: any; // number, but it is implemented as string
  inputRef?: Ref<any>;
  style?: Record<string, unknown>;
  dark?: boolean;
  bold?: boolean;
  autoHeight?: boolean;
}

const Textarea = (props: Props) => {
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {}, [hasFocus]);

  const handleFocus = () => {
    setHasFocus(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = (e) => {
    setHasFocus(false);
    if (window.rudderanalytics) {
      const gaClientId =
        window.ga && window.ga?.getAll ? window.ga?.getAll()[0]?.get('clientId') : Cookies.get('_ga')?.slice(6);
      (window.rudderanalytics as any)?.track('edit_text_area', {
        initial_anonymous_id: Cookies.get('initial_anonymous_id'),
        user_public_id: Cookies.get('user_public_id'),
        uuid: Cookies.get('uuid'),
        ga_client_id: gaClientId,
        data: {
          value: e.target.value,
          name: e.target.name,
        },
      });
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const {
    label = '',
    hasError = false,
    id,
    name,
    hasValue = false,
    placeholder = 'Describe your boat...',
    defaultValue,
    onChange,
    onFocus,
    onBlur,
    maxLength,
    inputRef,
    style,
    dark,
    bold,
    autoHeight = false,
  } = props;

  const updateHeight = (e) => {
    e.persist();
    e.target.style.height = '1px';
    e.target.style.height = `${25 + e.target.scrollHeight}px`;
  };

  return (
    <TextareaWrapper
      className={`${hasError ? 'error' : ''} ${label !== '' ? 'hasLabel' : ''} ${hasFocus ? 'hasFocus' : ''} ${
        hasValue ? 'hasValue' : ''
      } `}
    >
      {label !== '' && (
        <TextareaLabel
          className={`${hasValue || hasFocus ? 'TextareaLabelIsFocused' : ''} ${hasError ? 'error' : ''} ${
            label !== '' ? 'hasLabel' : ''
          }`}
        >
          {label}
        </TextareaLabel>
      )}
      {placeholder !== '' && (
        <TextareaPlaceholder
          data-testid="textarea-placeholder"
          className={`${!hasValue && hasFocus ? 'TextareaPlaceholderShow' : ''} ${!label ? 'noLabel' : ''}`}
        >
          {placeholder}
        </TextareaPlaceholder>
      )}
      <TextareaControl
        id={id}
        className={`${hasError ? 'error' : ''} ${dark ? 'TextareaDark' : ''} ${bold ? 'TextareaBold' : ''} ${
          !label ? 'noLabel' : ''
        }`}
        name={name}
        onChange={onChange}
        onKeyUp={autoHeight ? updateHeight : undefined}
        onFocus={() => handleFocus()}
        onBlur={handleBlur}
        maxLength={maxLength}
        defaultValue={defaultValue}
        ref={inputRef}
        style={style}
      />
    </TextareaWrapper>
  );
};

export default Textarea;

const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TextareaLabel = styled.label`
  transform: translate(12px, 15px) scale(1);
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: $rock;
  transform-origin: top left;
  z-index: 1;

  &.TextareaLabelIsFocused {
    transform: translate(11px, 5px) scale(0.75);
    color: #8e9697;
  }
`;

const TextareaPlaceholder = styled.div`
  transform: translate(12px, 30px) scale(1);
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #8e9697;
  opacity: 0.4;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease;
  width: 90%;
  z-index: 1;
  &.noLabel {
    transform: translate(12px, 12px) scale(1);
  }
  &.TextareaPlaceholderShow {
    opacity: 0.4;
    visibility: visible;
    transition: all 200ms ease;
  }
`;

const TextareaControl = styled.textarea`
  padding: 0.9em;
  -webkit-appearance: none;
  box-shadow: none;
  outline: none;
  color: #17233c;
  resize: vertical;
  border: solid 1px #dbdfe5;
  border-radius: 6px;
  padding-top: 28px;
  padding-left: 12px;
  width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &.TextareaDark {
    color: white;
    background: rgba(0, 0, 0, 0.16);
    border: solid 0px rgba(0, 0, 0, 0.16);
    &:focus {
      border: solid 0px #8e9697;
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.36);
    }
    &:placeholder-shown {
      @media ${mediaSmMax} {
        height: 320.1px;
      }
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &::-webkit-autofill:focus {
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &.TextareaBold {
    font-weight: 600;
  }

  &.error {
    border-color: #bc350a;
    &::placeholder {
      color: #bc350a;
    }
  }

  &.noLabel {
    padding-top: 10px;
  }
`;
