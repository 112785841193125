import { useEffect } from 'react';
import useRudderStackTrack from './useRudderStackTrack';

export default function useTrackFormErrors<T>(errors: T, pageName?: string) {
  const rudderTrack = useRudderStackTrack();

  useEffect(() => {
    const entries = Object.entries(errors);
    if (entries.length > 0) {
      entries.forEach(([key, value]) => {
        rudderTrack('form_error', {
          input_name: key,
          message: value.message,
          pageName,
        });
      });
    }
  }, [errors]);
}
