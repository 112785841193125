import { ReactNode } from 'react';
import { Tooltip as TooltipComponent, Position, Trigger } from 'react-tippy';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  place?: Position;
  triggerWith: ReactNode;
  distance?: number;
  trigger?: Trigger;
  interactive?: boolean;
  className?: string;
  style?: object;
};

const Tooltip = ({
  children,
  place = 'top',
  triggerWith,
  distance = 10,
  trigger = 'mouseenter',
  interactive = false,
  className,
  style = null,
}: Props) => {
  const headerWithChildren = <ToolTipWrapper>{children}</ToolTipWrapper>;

  return (
    // @ts-ignore
    <TooltipComponent
      html={headerWithChildren}
      position={place}
      className={className}
      distance={distance}
      trigger={trigger}
      interactive={interactive}
      animation="fade"
      theme="light"
      multiple={false}
      unmountHTMLWhenHide
      arrow
      style={style}
    >
      <TooltipSpan>{triggerWith}</TooltipSpan>
    </TooltipComponent>
  );
};

export default Tooltip;

const ToolTipWrapper = styled.div`
  padding-left: 12px;
  padding-right: 12px;
`;

const TooltipSpan = styled.span.attrs({ role: 'tooltip' })``;
