import { useMemo } from 'react';
import { useBoatDetails } from 'components/pdp/v3/hooks';
import { useAtom, useAtomValue } from 'jotai';
import {
  bookingBoatIdAtom,
  bookingDurationAtom,
  selectedBookingPackageAtom,
  bookingTripTimeAtom,
  tripStartDayjsAtom,
  tripStartFormattedAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { useDefaultStartTimes } from 'hooks/queries';
import SelectInput from './SelectInput';
import { getBoatTimesOptions, getTimeOptions } from './utils';

const TimePicker = ({
  onChange = () => {},
  beginLimit = null,
  endLimit = null,
  step = null,
  modalVersion = false,
  editable = true,
  calendar,
  error = null,
  useDuration = false,
  isOwner = false,
}) => {
  const bookingBoatId = useAtomValue(bookingBoatIdAtom);
  const { boatDetails: bookingBoatDetails } = useBoatDetails(bookingBoatId);
  const { defaultStartTimes } = useDefaultStartTimes();

  const bookingDuration = useAtomValue(bookingDurationAtom);
  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);
  const tripStart = useAtomValue(tripStartDayjsAtom);
  const tripStartFormatted = useAtomValue(tripStartFormattedAtom);
  const [tripTime, setTripTime] = useAtom(bookingTripTimeAtom);

  const options = useMemo(
    () =>
      getTimeOptions({
        beginLimit,
        endLimit,
        step,
        startDate: tripStartFormatted,
        calendar,
        timeOptions: getBoatTimesOptions(tripStart, defaultStartTimes, bookingBoatDetails?.start_times || {}, calendar),
        defaultStartTimes,
        useDuration,
        bookingDuration,
        selectedBookingPackage,
      }),
    [
      beginLimit,
      endLimit,
      step,
      tripStart,
      tripStartFormatted,
      calendar,
      defaultStartTimes,
      useDuration,
      bookingBoatDetails?.start_times,
      bookingDuration,
      selectedBookingPackage,
    ]
  );

  const errorToShow =
    options.length === 0 && Object.keys(calendar).length !== 0 ? 'There are no available times for this day' : error;

  return (
    <div data-testid="pdp-booking-widget-time-picker-container">
      <SelectInput
        name="TimePicker"
        error={errorToShow}
        modalVersion={modalVersion}
        icon={<StartTimeIcon />}
        options={options}
        defaultValue={tripTime}
        onChange={onChange}
        setter={setTripTime}
        title="Start time"
        isDisabled={!editable || options.length === 0}
        isOwner={isOwner}
      />
    </div>
  );
};

const StartTimeIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6728 9.67914H7.99576C7.6777 9.67914 7.41395 9.41538 7.41395 9.09732V5.42802C7.41395 5.10996 7.6777 4.8462 7.99576 4.8462C8.31382 4.8462 8.57758 5.10996 8.57758 5.42802V8.51551H11.6651C11.9831 8.51551 12.2469 8.77926 12.2469 9.09732C12.2469 9.41538 11.9909 9.67914 11.6728 9.67914ZM16.2188 8.50002C16.2188 4.24113 12.7589 0.78125 8.5 0.78125C4.24111 0.78125 0.78125 4.24889 0.78125 8.50002C0.78125 12.7589 4.24887 16.2187 8.5 16.2187C12.7511 16.2187 16.2188 12.7589 16.2188 8.50002ZM15.0551 8.50002C15.0551 12.115 12.115 15.0551 8.5 15.0551C4.88499 15.0551 1.94488 12.115 1.94488 8.50002C1.94488 4.885 4.88499 1.94488 8.5 1.94488C12.1073 1.93713 15.0551 4.885 15.0551 8.50002Z"
      fill="#5E696A"
    />
  </svg>
);

export default TimePicker;
