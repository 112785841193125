import { useForm } from 'react-hook-form';
import { FieldValues, UseFormOptions } from 'react-hook-form/dist/types';
import useTrackFormErrors from './useTrackFormErrors';

export default function useFormWithErrorTracking<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(options: UseFormOptions<TFieldValues, TContext> = {}, pageName = '') {
  const methods = useForm(options);

  useTrackFormErrors(methods.errors, pageName);

  return methods;
}
