import styled from 'styled-components';

interface Props {
  error: any;
  message?: string;
  className?: string;
  dataTestId?: string;
}

const ErrorMessage = ({ error, message, className, dataTestId }: Props) =>
  error ? (
    <ErrorMessageWrapper className={`${className || ''} ErrorMessage`} data-testid={dataTestId}>
      <ErrorMessageContent>
        <ErrorMessageItem className="ErrorMessageItem">
          {error.message || message || 'This field is required'}
        </ErrorMessageItem>
      </ErrorMessageContent>
    </ErrorMessageWrapper>
  ) : null;

export default ErrorMessage;

const ErrorMessageWrapper = styled.div`
  &.InputTextError {
    position: absolute;
  }
`;

const ErrorMessageContent = styled.div`
  background-color: transparent;
  color: #bc350a;
`;

const ErrorMessageItem = styled.div`
  font-size: 12px;
  padding: 0;
  line-height: 18px;
  margin-bottom: 12px;
  margin-top: 4px;
`;
