import styled, { css } from 'styled-components';
import { useState } from 'react';
import {
  isBookingMultiDayAtom,
  tripFinishDayjsAtom,
  tripStartDayjsAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { useAtomValue } from 'jotai';
import { useSetModalTitleBackdrop } from 'components/remodal/v2/hooks';
import BookingCalendarWrapper from './BookingCalendarWrapper';
import { useBreakpoint } from '../BreakpointProvider';
import useRudderStackTrack from '../../hooks/useRudderStackTrack';

const CalendarInput = ({
  label = 'Start date',
  resetTripTime = false,
  onChange = null,
  error = null,
  hidePrice = false,
  hideMultiDay = false,
  modalVersion = false,
  className = null,
  monthsToShow = 2,
  onMultiDayChange = null,
  hideMobileHeader = false,
  isOwner = false,
}) => {
  const setModalTitleBackDrop = useSetModalTitleBackdrop();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const isBookingMultiDay = useAtomValue(isBookingMultiDayAtom);
  const tripFinish = useAtomValue(tripFinishDayjsAtom);
  const tripStart = useAtomValue(tripStartDayjsAtom);

  const startDate = tripStart?.format('MMM D');

  const rudderTrack = useRudderStackTrack();

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;

  const toggleCalendar = () => {
    setModalTitleBackDrop?.(!calendarOpen);
    setCalendarOpen(!calendarOpen);

    if (!calendarOpen) {
      rudderTrack('selection_date_start', {
        action: 'start',
      });
    }
  };

  const getShownDate = () => {
    const date = tripStart ? tripStart.toDate() : new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDayOfMonth;
  };

  return (
    <div className={className}>
      <Input error={error} onClick={toggleCalendar} modalVersion={modalVersion} data-testid="components-calendar-input">
        <ValueWrapper>
          <Label className={`${!!startDate && 'active'}`} active={!!startDate}>
            {label}
          </Label>
          <Value data-testid="calendar-input-date-value">
            {startDate}
            {isBookingMultiDay &&
              tripFinish &&
              ` - ${tripFinish.format(tripFinish.isSame(tripStart, 'month') ? 'D' : 'MMM D')}`}
            {tripStart && `, ${tripStart.format('YYYY')}`}
          </Value>
          <ErrorMessage>{error}</ErrorMessage>
        </ValueWrapper>
        <ArrowWrapper>
          <CalendarIcon />
        </ArrowWrapper>
      </Input>
      {calendarOpen && (
        <BookingCalendarWrapper
          shownDate={getShownDate()}
          monthsToShow={monthsToShow}
          onChange={onChange}
          hideMultiDay={hideMultiDay}
          hidePrice={hidePrice}
          resetTripTime={resetTripTime}
          toggleCall={toggleCalendar}
          onMultiDayChange={onMultiDayChange}
          mobile={smallScreen}
          hideMobileHeader={hideMobileHeader}
          isOwner={isOwner}
        />
      )}
    </div>
  );
};

const ErrorMessage = styled.div`
  color: #bc350a;
  font-weight: 500;
  position: absolute;
  bottom: -0px;
  font-size: 12px;
`;

const CalendarIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9624 3.7653H12.4505V1.37806C12.4505 1.0518 12.1869 0.78125 11.869 0.78125C11.5511 0.78125 11.2874 1.0518 11.2874 1.37806V3.7653H4.92169V1.37806C4.92169 1.0518 4.65807 0.78125 4.34017 0.78125C4.02227 0.78125 3.75865 1.0518 3.75865 1.37806V3.7653H3.03756C1.79698 3.7653 0.78125 4.80774 0.78125 6.08093V13.9031C0.78125 15.1763 1.79698 16.2188 3.03756 16.2188H13.9624C15.203 16.2188 16.2188 15.1763 16.2188 13.9031V6.08093C16.2188 4.79978 15.203 3.7653 13.9624 3.7653ZM15.0557 13.8952C15.0557 14.5159 14.5672 15.0172 13.9624 15.0172H3.03756C2.43278 15.0172 1.9443 14.5159 1.9443 13.8952V6.07297C1.9443 5.45228 2.43278 4.95097 3.03756 4.95097H13.9624C14.5672 4.95097 15.0557 5.45228 15.0557 6.07297V13.8952ZM12.8304 6.47084H4.16959C3.85169 6.47084 3.58807 6.7414 3.58807 7.06765V12.9005C3.58807 13.2268 3.85169 13.4973 4.16959 13.4973H12.8304C13.1483 13.4973 13.4119 13.2268 13.4119 12.9005V7.06765C13.4119 6.7414 13.1483 6.47084 12.8304 6.47084ZM12.2489 12.3037H4.75111V7.66446H12.2489V12.3037Z"
      fill="#5E696A"
    />
  </svg>
);

const ValueWrapper = styled.div``;

export const Value = styled.div`
  font-weight: 600;
  padding-top: 8px;
  font-size: 16px;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`;

export const Label = styled.div<{ active: boolean }>`
  color: #5e696a;
  align-self: center;
  position: absolute;

  ${({ active }) =>
    active &&
    `
      transform: translateY(-7px);
      font-size: 12px;
  `}
`;

export const Input = styled.div<{ error?: string; modalVersion: boolean }>`
  position: relative;
  display: flex;
  cursor: pointer;
  background-color: #fff;
  border-radius: 6px;
  height: 50px;
  padding: 12px 0 8px 0;
  align-content: center;
  justify-content: space-between;

  ${({ error }) =>
    error &&
    css`
      border-color: #bc350a !important;

      path,
      line {
        fill: #bc350a;
      }

      ${Label} {
        color: #bc350a;
      }
    `}

  ${({ modalVersion }) =>
    modalVersion &&
    css`
      border: 1px solid #cdd1d2;
      padding: 12px 12px 8px 12px;
    `}
`;

export default CalendarInput;
