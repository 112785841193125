import React, { useRef, useState } from 'react';
import { useBreakpoint } from 'components/BreakpointProvider';
import { mediaMMdMax } from 'helpers/breakpoints';
import styled, { css } from 'styled-components';
import { useSetModalTitleBackdrop } from 'components/remodal/v2/hooks';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { FlexCol } from '../../primitives/flex';
import useRudderStackTrack from '../../../hooks/useRudderStackTrack';

const NewBookingSelectInput = ({
  defaultValue: value,
  options = [],
  onChange = undefined,
  isDisabled = false,
  title = null,
  name,
  height = '80%',
  error,
  setter,
  modalVersion = false,
  icon = null,
  className = null,
  isOwner = false,
}) => {
  const setModalTitleBackDrop = useSetModalTitleBackdrop();
  const [isOpen, setIsOpen] = useState(false);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;
  const scrollDownRef = useRef<HTMLUListElement>();
  const mobileRef = useRef();

  useOnClickOutside(mobileRef, () => setIsOpen(false));

  const rudderTrack = useRudderStackTrack();

  const toggling = () => {
    setModalTitleBackDrop?.(!isOpen);
    setIsOpen(!isOpen);

    if (!isOpen) {
      rudderTrack('selection_component_interaction', {
        action: 'start',
        name,
      });
    }
  };

  const onOptionClicked = (optionValue) => () => {
    setter(optionValue);
    setModalTitleBackDrop?.(false);
    setIsOpen(false);
    rudderTrack('selection_component_interaction', {
      action: 'value_selected',
      name,
      value: optionValue,
    });
    if (onChange) {
      onChange(optionValue);
    }
  };

  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, () => {
    if (!isOpen) return;
    setModalTitleBackDrop?.(false);
    setIsOpen(false);
  });

  return (
    <Main
      modalVersion={modalVersion}
      ref={wrapperRef}
      className={`
      ${isOpen ? 'isOpen' : ''}
      ${error ? 'hasError' : ''}
      ${isDisabled ? 'isDisabled' : ''}
      ${smallScreen ? 'smallScreen' : ''}
      ${className}
      `}
    >
      <DropDownContainer>
        <DropDownHeader onClick={toggling} data-testid={`components-forms-duration-picker-${title?.replace(' ', '-')}`}>
          <FlexCol>
            {value?.label ? (
              <ValueContainer modalVersion={modalVersion}>
                <SmallLabel>{title}</SmallLabel>
                <SelectInputValue>{value.label}</SelectInputValue>
              </ValueContainer>
            ) : (
              <Placeholder modalVersion={modalVersion}>{title}</Placeholder>
            )}
          </FlexCol>

          <SelectIconWrapper icon={icon} modalVersion={modalVersion} className={isOpen && 'open'}>
            {icon || <Arrow />}
          </SelectIconWrapper>
        </DropDownHeader>
        <ErrorMessage modalVersion={modalVersion}>{error}</ErrorMessage>

        {smallScreen && isOpen && (
          <>
            <BGOverlay />
            <MobileWrapper height={height} ref={mobileRef}>
              <MobileHeader>
                <span>{title}</span>

                <CloseIcon
                  onClick={() => {
                    setModalTitleBackDrop?.(false);
                    setIsOpen(false);
                  }}
                  fill="#8e9697"
                  height={20}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1008 1008"
                >
                  <path d="M227.8 157.3l633.9 633.9c19.5 19.5 19.5 51 0 70.4-19.5 19.5-51 19.5-70.4 0l-634-633.8c-19.5-19.5-19.5-51 0-70.4 19.5-19.5 51-19.5 70.5-.1z" />
                  <path d="M146.3 780.2l633.9-633.9c19.5-19.5 51-19.5 70.4 0 19.5 19.5 19.5 51 0 70.4l-633.9 634c-19.5 19.5-51 19.5-70.4 0-19.5-19.5-19.5-51 0-70.5z" />
                </CloseIcon>
              </MobileHeader>
              <DropDownListContainerMobile>
                <DropDownListMobile ref={scrollDownRef}>
                  {options
                    .filter((o) => o.available)
                    .map((option) => (
                      <ListItem
                        isOwner={isOwner}
                        className={`
                    ${option.value === value?.value ? 'selected' : ''}
                    ${option.available === false ? 'notAvailable' : ''}
                    `}
                        onClick={onOptionClicked(option)}
                        key={Math.random()}
                      >
                        {option.label}
                      </ListItem>
                    ))}
                </DropDownListMobile>
              </DropDownListContainerMobile>
            </MobileWrapper>
          </>
        )}
        {isOpen && !smallScreen && (
          <DropDownListContainer>
            <DropDownList ref={scrollDownRef}>
              {options
                .filter((o) => o.available)
                .map((option) => (
                  <ListItem
                    isOwner={isOwner}
                    data-testid={`components-forms-duration-${option.label.replace(' ', '-')}-option`}
                    className={`
                    ${option.value === value?.value ? 'selected' : ''}
                    ${option.available === false ? 'notAvailable' : ''}
                `}
                    onClick={onOptionClicked(option)}
                    key={Math.random()}
                  >
                    {option.label}
                  </ListItem>
                ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  );
};

const ErrorMessage = styled.div<{ modalVersion: boolean }>`
  position: absolute;
  ${({ modalVersion }) =>
    modalVersion &&
    css`
      position: unset;
    `}
  padding-top: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #bc350a;
  bottom: 0px;
`;

export const SelectIconWrapper = styled.div<{ modalVersion: boolean; icon: boolean }>`
  margin-left: auto;
  margin-right: 0px;
  ${mediaMMdMax} {
    display: flex;
  }

  ${({ modalVersion }) =>
    modalVersion &&
    css`
      display: flex;
      margin-right: 12px;
    `}

  ${({ icon }) =>
    !icon &&
    css`
      &.open {
        transform: rotate(180deg);
      }
    `}
`;

const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
    <path
      d="M6 7L10.8619 1.85762C11.046 1.66294 11.046 1.3407 10.8619 1.14601C10.6779 0.951329 10.3732 0.951329 10.1891 1.14601L6 5.57678L1.81085 1.14601C1.62679 0.951329 1.32212 0.951329 1.13805 1.14601C0.953983 1.3407 0.953983 1.66294 1.13805 1.85762L6 7Z"
      fill="#0E7873"
    />
    <path
      d="M6 7L5.63668 7.34351C5.73112 7.4434 5.86253 7.5 6 7.5C6.13747 7.5 6.26888 7.4434 6.36332 7.34351L6 7ZM10.8619 1.85762L10.4986 1.51411L10.8619 1.85762ZM10.1891 1.14601L9.82583 0.802506L10.1891 1.14601ZM6 5.57678L5.63668 5.92029L6 6.30457L6.36332 5.92029L6 5.57678ZM1.81085 1.14601L2.17418 0.802506V0.802506L1.81085 1.14601ZM1.13805 1.85762L0.774731 2.20113L1.13805 1.85762ZM6.36332 7.34351L11.2253 2.20113L10.4986 1.51411L5.63668 6.65649L6.36332 7.34351ZM11.2253 2.20113C11.5916 1.8137 11.5916 1.18994 11.2253 0.802506L10.4986 1.48952C10.4971 1.48785 10.4972 1.48728 10.498 1.48936C10.4989 1.49177 10.5 1.49614 10.5 1.50182C10.5 1.50749 10.4989 1.51187 10.498 1.51428C10.4972 1.51635 10.4971 1.51578 10.4986 1.51411L11.2253 2.20113ZM11.2253 0.802506C10.8439 0.399165 10.2072 0.399165 9.82583 0.802506L10.5525 1.48952C10.5507 1.49135 10.547 1.49439 10.5414 1.49675C10.5362 1.49895 10.5308 1.5 10.5255 1.5C10.5203 1.5 10.5149 1.49895 10.5097 1.49675C10.5041 1.49439 10.5004 1.49135 10.4986 1.48952L11.2253 0.802506ZM9.82583 0.802506L5.63668 5.23328L6.36332 5.92029L10.5525 1.48952L9.82583 0.802506ZM6.36332 5.23328L2.17418 0.802506L1.44753 1.48952L5.63668 5.92029L6.36332 5.23328ZM2.17418 0.802506C1.79283 0.399165 1.15608 0.399165 0.774731 0.802506L1.50137 1.48952C1.49964 1.49135 1.49588 1.49439 1.49034 1.49675C1.48515 1.49895 1.47972 1.5 1.47445 1.5C1.46918 1.5 1.46376 1.49895 1.45857 1.49675C1.45303 1.49439 1.44926 1.49135 1.44753 1.48952L2.17418 0.802506ZM0.774731 0.802506C0.408423 1.18994 0.408423 1.8137 0.774731 2.20113L1.50137 1.51411C1.50295 1.51578 1.50277 1.51635 1.50198 1.51428C1.50107 1.51187 1.5 1.50749 1.5 1.50182C1.5 1.49614 1.50107 1.49177 1.50198 1.48936C1.50277 1.48728 1.50295 1.48785 1.50137 1.48952L0.774731 0.802506ZM0.774731 2.20113L5.63668 7.34351L6.36332 6.65649L1.50137 1.51411L0.774731 2.20113Z"
      fill="#0E7873"
    />
  </svg>
);

export const SelectInputValue = styled.div`
  font-size: 16px;
  color: #17233c;
  font-weight: 600;
  margin-top: 1px;
`;

const Main = styled.div<{ modalVersion: boolean }>`
  border-radius: 6px !important;
  cursor: pointer;
  position: relative;

  height: 50px;

  ${({ modalVersion }) =>
    modalVersion &&
    css`
      ${mediaMMdMax} {
        position: unset;
      }
      border: 1px solid #cdd1d2;
      &.hasError {
        margin-bottom: 20px;
      }

      ${ErrorMessage} {
        bottom: -20px;
      }

      &.isOpen {
        border: solid 1px #8e9697 !important;
      }
    `}

  &.isDisabled {
    cursor: not-allowed;
    ${SelectInputValue} {
      color: #8e9697;
    }
    * {
      pointer-events: none;
    }
  }

  &.hasError {
    color: #bc350a;
    border-color: #bc350a;

    path,
    line {
      fill: #bc350a;
    }

    circle,
    line {
      stroke: #bc350a;
    }
  }
`;

const DropDownContainer = styled.div`
  width: 100%;
`;

export const DropDownHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Placeholder = styled.div<{ modalVersion: boolean }>`
  font-size: 16px;
  padding: 12px 12px 12px 0;
  color: #5e696a;

  ${({ modalVersion }) =>
    modalVersion &&
    css`
      padding: 12px;
    `}
`;

const ValueContainer = styled.div<{ modalVersion: boolean }>`
  display: flex;
  flex-direction: column;

  padding: 4px 0px 6px 0px;

  ${({ modalVersion }) =>
    modalVersion &&
    css`
      padding: 4px 12px 6px 12px;
    `}
`;

export const SmallLabel = styled.div`
  color: #5e696a;
  font-size: 12px;
`;

const DropDownListContainer = styled.div`
  position: absolute;
  border-radius: 10px;
  width: 100%;
  z-index: 999;
  top: 55px;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
`;

const DropDownListContainerMobile = styled.div`
  width: 100%;
  background-color: white;
`;

const DropDownList = styled.ul`
  margin: 0;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  background: #ffffff;
  color: #3faffa;
  max-height: 350px;
  overflow-y: auto;
  padding: 15px 15px 15px 15px;
`;

const DropDownListMobile = styled.ul`
  margin: 0;
  color: #3faffa;
  max-height: 100%;
  overflow-y: auto;
  padding: 25px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  ${mediaMMdMax} {
    padding-top: 80px;
  }
`;

const ListItem = styled.li<{ isOwner: boolean }>`
  list-style: none;
  font-size: 14px;
  flex: 45%;
  color: black;
  line-height: 1.6;
  padding: 3px 10px;
  font-weight: 600;
  border-radius: 4px;

  height: 55px;
  max-width: 140px;
  border: 1px solid #dbdfe5;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(219, 223, 229, 0.5);
  }

  &.selected {
    color: #0751c2;
    border: none;
    background: #eef3fb;
    ${({ isOwner }) =>
      isOwner &&
      css`
        border: 1px solid #0e7873;
        color: #0e7873;
        background: #eefbf9;
      `}
  }

  &.notAvailable {
    background-color: #f2f2f2 !important;
    color: black;
    pointer-events: none;
    cursor: not-allowed;

    * {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  ${mediaMMdMax} {
    max-width: unset;
    flex: unset;
  }
`;

const MobileHeader = styled.div`
  font-weight: 600;
  color: rgb(23, 35, 60);
  padding: 24px 24px 12px;
  font-size: 20px;
  display: flex;
  align-items: center;
  background: white;
  z-index: 100;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  ${mediaMMdMax} {
    position: fixed;
    width: 100%;
  }
`;

const CloseIcon = styled.svg`
  margin-left: auto;
  color: rgb(216, 216, 216);
`;

const BGOverlay = styled.div`
  position: absolute;
  left: 0;
  background: rgba(10, 37, 47, 0.6);
  opacity: 1;
  z-index: 100000;
  width: 100%;
  height: 200%;
  top: 0;
`;

const MobileWrapper = styled.div<{ height: string }>`
  position: fixed;
  bottom: 0;
  background: white;
  width: 100vw;
  left: 0;
  height: ${({ height }) => height};
  z-index: 100000;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: auto;
`;

export default NewBookingSelectInput;
