import invert from 'lodash/invert';
import pickBy from 'lodash/pickBy';
import { valueEmpty } from 'helpers';
import dayjs, { Dayjs } from 'dayjs';
import { weekDays } from '../boat/constants';

export const isEarlierThanEndLimit = (timeValue, endTime, lastValue) => {
  const timeValueIsEarlier = dayjs(timeValue, 'HH:mm').diff(dayjs(endTime, 'HH:mm')) < 0;
  const timeValueIsLaterThanLastValue =
    lastValue === undefined ? true : dayjs(lastValue, 'HH:mm').diff(dayjs(timeValue, 'HH:mm')) < 0;
  return timeValueIsEarlier && timeValueIsLaterThanLastValue;
};

export const isDurationAvailable = (
  date: string,
  boatCalendar: any,
  packageType: string,
  time: string | null = null,
  duration: number | null = null
) => {
  const dayData = boatCalendar[date];
  const limitName = packageType === 'captained' ? 'captained_durations' : 'bareboat_durations';

  if (!time || !duration) return true;

  // the whole day is not available
  if (dayData?.available === false) return false;

  const timeData = dayData?.updated_start_times?.[time];

  // if there isnt updated start time it's available
  if (!timeData) return true;

  // if time is unavailable duration is also not available
  if (timeData.available === false) return false;

  // we check if the duration is avaiable for that time and package type
  return timeData[limitName]?.includes(duration);
};

export const getBoatTimesOptions = (startDateValue: Dayjs, defaultStartTimes, boatStartTimes, calendar) => {
  const filteredDefaultValues = (defaultStartTimes || []).filter((d) => d.default);

  const dateValue = startDateValue || dayjs();
  const weekDay = dateValue.format('dddd');
  const weekDaysInvert = invert(weekDays);
  const weekDayNSelected = weekDaysInvert[weekDay];
  let availableTimes = (filteredDefaultValues || []).map((dv) => dv.value);

  if (!valueEmpty(boatStartTimes)) {
    availableTimes = boatStartTimes[weekDayNSelected];
  }
  // select from calendar if available date is not default
  const calendarDay = dateValue;
  const details = calendar[calendarDay.format('YYYY-MM-DD')];

  if (details && details.available && !valueEmpty(details?.updated_start_times)) {
    // @ts-ignore
    const onlyNotAvailabe = Object.entries(details.updated_start_times).filter((st) => st[1].available === false);
    const unavailableTimes = onlyNotAvailabe.map((st) => st[0]);

    if (!valueEmpty(availableTimes)) {
      availableTimes = (availableTimes || []).filter((item) => !unavailableTimes.includes(item));
    } else {
      availableTimes = (filteredDefaultValues || []).map((dv) => dv.value);
    }
  }

  if (valueEmpty(availableTimes)) {
    availableTimes = (filteredDefaultValues || []).map((dv) => dv.value);
  }

  return availableTimes;
};

export const getTimeOptions = ({
  beginLimit,
  endLimit,
  step,
  startDate,
  calendar,
  timeOptions,
  defaultStartTimes,
  useDuration = false,
  bookingDuration,
  selectedBookingPackage,
}) => {
  let timeValue = beginLimit || '08:00';
  let lastValue;
  const endTime = endLimit || '21:00';
  const timeStep = step || 60;

  let options = [];
  let unavailableTimes = [];

  const dateSelected = startDate || dayjs().format('YYYY-MM-DD');
  const calendarDate = calendar[dateSelected];

  if (calendarDate) {
    const selectedUnavailableTimes = pickBy(
      calendarDate.updated_start_times,
      (value) => !valueEmpty(value) && value.available === false
    );
    unavailableTimes = Object.keys(selectedUnavailableTimes);
  }

  const isAvailable = (timeOption, allAvailableTimes) => {
    const timeStatus = calendarDate.updated_start_times[timeOption];

    if (
      timeStatus?.available &&
      (timeStatus?.captained_durations || timeStatus?.bareboat_durations) &&
      useDuration &&
      bookingDuration?.value
    ) {
      const limitName =
        selectedBookingPackage?.package_type === 'captained' ? 'captained_durations' : 'bareboat_durations';
      return timeStatus[limitName]?.includes(parseInt(bookingDuration?.value, 10));
    }
    return allAvailableTimes.includes(timeOption);
  };

  if (!valueEmpty(timeOptions)) {
    const defaultTimes = defaultStartTimes.map((dv) => dv.value);
    const allAvailableTimes = timeOptions.filter((o) => !unavailableTimes.includes(o));
    options = defaultTimes.map((o) => ({
      label: dayjs(o, 'HH:mm').format('h:mm A'),
      value: o,
      available: calendarDate ? isAvailable(o, allAvailableTimes) : allAvailableTimes.includes(o),
    }));
  } else {
    options.push({ label: dayjs(timeValue, 'HH:mm').format('h:mm A'), value: timeValue });
    while (isEarlierThanEndLimit(timeValue, endTime, lastValue)) {
      lastValue = timeValue;

      timeValue = dayjs(timeValue, 'HH:mm').add(timeStep, 'minutes').format('HH:mm');

      options.push({ label: dayjs(timeValue, 'HH:mm').format('h:mm A'), value: timeValue });
    }
  }

  if (!calendarDate?.available || calendarDate?.availablity === 'none') return [];

  return options;
};
